<template>
  <section>
    <h1>
      {{ $t('dashboard.doctor.patientStart') }} : <span>{{ user.username }}</span>
    </h1>

    <v-progress-circular indeterminate color="primary" v-if="loading"/>

    <div v-else ref="formList">
      <article
          v-for="(form, index) in localeForms"
          :key="index"
          :class="{ complete: form.status === 2, partial: form.status === 1 }"
          ref="formListItem"
      >
        <router-link :to="{ name: 'Form', params: { surveyId: form.id, userId: patientId } }"
                     ref="surveyListItemLink"
        >
          <h2>{{ form.label }}</h2>
        </router-link>
      </article>

      <!-- TODO : USE INCLUSION FORMS RESULT TO DISPLAY THESE BUTTONS ? -->
      <footer style="clear: both;">
        <v-btn :to="{ name: 'DashboardPatient', params: { patientId: patientId } }" color="secondary" depressed>
          {{ $t('dashboard.doctor.buttons.return') }}
        </v-btn>
        <v-btn color="primary" @click="dialog.display = true"  v-if="this.surveyCat === 'INCLUSION'" depressed>
          {{ inclusionButton['button'] }}
        </v-btn>
        <confirm-dialog
            v-model="dialog.display"
            :title="inclusionButton['title']"
            :body="inclusionButton['body']"
            :has-action="true"
            @cancel="dialog.display = false"
            @valid="onPatientInclusion(!user.included)"
        />
      </footer>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PatientForms',

  components: {
    ConfirmDialog: () => import('@/components/Template/ConfirmDialog'),
  },

  props: {
    patientId: {
      required: true,
    },
    surveyCat: {
      required: true,
    },
  },

  data: () => ({
    loading: false,
    localeForms: [],
    dialog: {
      display: false,
      errorMessage: ''
    },
  }),

  computed: {
    ...mapGetters([
      'userForms',
      'user',
    ]),

    inclusionButton() {
      if (this.user.included) {
        return {
          'button': this.$t('dashboard.doctor.buttons.cancelInclusion'),
          'title': this.$t('dashboard.doctor.actions.cancelInclusionDialog.title'),
          'body': this.$t('dashboard.doctor.actions.cancelInclusionDialog.body')
        }
      }
      else {
        return {
          'button': this.$t('dashboard.doctor.buttons.include'),
          'title': this.$t('dashboard.doctor.actions.includeDialog.title'),
          'body': this.$t('dashboard.doctor.actions.includeDialog.body')
        }
      }
    }
  },

  created () {
    this.loading = true;
    this.userForms.splice(0)
    this.loadUser({ id: this.patientId });
    this.loadUserForms(this.patientId)
        .then(() => {
          this.userForms.forEach(userForm => {
            if(userForm.category == this.surveyCat) {
              this.localeForms.push({ ...userForm })
            }
          })
          this.loading = false
        })
  },

  methods: {
    ...mapActions([
      'loadUserForms',
      'loadUser',
      'updateUser',
      'displaySnackbar',
    ]),

    onPatientInclusion(include) {
      this.updateUser({
        user: this.user,
        data: {'included': include}
      })
          .then(() => {
            this.dialog.display = false;
            this.displaySnackbar({
              status: true,
              type: 'success',
              text: this.$t('dashboard.doctor.inclusion'),
              timeout: 10000,
            })
          })
    }
  },
}
</script>

<style lang="scss" scoped>
@import 'src/assets/style/formSurveyList';

section {
  footer {
    display:flex;
    justify-content: center;
    flex-wrap: wrap;

    a:first-of-type {
      margin-right: 20px;
      margin-bottom: 20px;
    }
  }
}
</style>
